<!-- 添加洗车 -->
<template>
  <div class="add-wash-consume">
    <div class="main-content">
      <van-cell class="title-box">
        <p class="title">洗车卡消费</p>
      </van-cell>
      <van-form @failed="onFailed" autocomplete="off" ref="washForm">
        <van-field
          readonly
          clickable
          v-model="ruleForm.cardName"
          name="cardName"
          label="洗车卡名称"
          placeholder="请选择洗车卡"
          @click="showWashPicker = true"
          required
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="showWashPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="carOptions"
            value-key="cardName"
            @confirm="onWashConfirm"
            @cancel="showWashPicker = false"
          />
        </van-popup>
        <van-field
          v-if="ruleForm.cardNo"
          readonly
          clickable
          v-model="ruleForm.cardNo"
          name="cardNo"
          label="洗车编号"
          placeholder="请选择洗车编号"
          disabled
          required
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          readonly
          clickable
          v-model="ruleForm.useTime"
          name="useTime"
          label="消费时间"
          placeholder="请选择消费时间"
          @click="showTimePicker = true"
          required
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="showTimePicker" position="bottom">
          <van-datetime-picker
            v-model="useTime"
            type="datetime"
            title="选择消费时间"
            @confirm="onTimeConfirm"
            @cancel="showTimePicker = false"
          />
        </van-popup>
        <van-field
          v-model="ruleForm.useMoney"
          label="消费金额(元)"
          name="useMoney"
          :formatter="formatter"
          :rules="[{ pattern, message: '消费金额格式错误' }]"
        />
        <van-field
          readonly
          clickable
          v-model="ruleForm.cartName"
          name="cartName"
          label="车辆"
          placeholder="请选择车辆"
          @click="showCarPicker = true"
          required
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="showCarPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="cardOptions"
            value-key="cartName"
            @confirm="onCarConfirm"
            @cancel="showCarPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          v-model="ruleForm.useStaffName"
          name="useStaffName"
          label="使用人"
          placeholder="请选择使用人"
          @click="showStaffPicker = true"
          required
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="showStaffPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="staffData"
            value-key="userName"
            @confirm="onStaffConfirm"
            @cancel="showStaffPicker = false"
          />
        </van-popup>
        <van-field
          v-model="ruleForm.remark"
          name="备注"
          label="备注"
          placeholder="请输入备注"
          maxlength="250"
          type="textarea"
          class="text"
        />
        <van-field name="uploader" label="上传小票">
          <template #input>
            <van-uploader
              :max-count="1"
              :max-size="10 * 1024 * 1024"
              v-model="fileList"
              :after-read="afterReadFile"
              @oversize="onOversize"
              :before-read="beforeRead"
            />
          </template>
        </van-field>
      </van-form>
    </div>

    <div class="footer-buttons">
      <van-button class="pass" @click="goBack">取消</van-button>
      <van-button class="reject-btn" native-type="submit" @click="validKeep"
        >确认</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getAllStaffList, uploadImg } from "@/api/common/index.js";
import {
  queryWashCardList,
  addConsumeBill,
  selectAll,
} from "@/api/car/cardManger.js";
export default {
  data() {
    return {
      ruleForm: {
        bizCode: "",
        cardNo: "",
        cartCode: "",
        remark: "",
        useImgCode: "",
        useMoney: "0",
        useStaffCode: "",
        useTime: "",
        cardName: "",
        cartName: "",
        useStaffName: "",
      },
      showWashPicker: false,
      carOptions: [],
      showTimePicker: false,
      minDate: new Date(2024, 0, 1),
      maxDate: new Date(2035, 0, 1),
      useTime: new Date(),
      pattern: /^\d{1,6}(\.\d{1,2})?$/,
      cardOptions: [],
      showCarPicker: false,
      staffData: [],
      showStaffPicker: false,
      fileList: [],
      loading: false,
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getWashCardList();
    this.getAllCar();
    this.getAllStaff();
  },

  methods: {
    formatter(value) {
      if (/^\d{1,6}(\.\d{1,2})?$/.test(value)) {
        return (this.ruleForm.useMoney = Number(value));
      }
      return value;
    },
    onFailed() {},
    getWashCardList() {
      queryWashCardList({ pageNum: 1, pageSize: 200 }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.columns = data.records.map((e) => {
              return e.cardName;
            });
            this.carOptions = data.records;
          }
        }
      });
    },
    getAllCar() {
      selectAll().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.cardOptions = data;
          }
        }
      });
    },
    getAllStaff() {
      getAllStaffList().then((res) => {
        let { data, code } = res;
        if (code == 200) {
          this.staffData = data;
        }
      });
    },
    onWashConfirm(value) {
      this.ruleForm.cardName = value.cardName;
      this.ruleForm.cardNo = value.cardNo;
      this.showWashPicker = false;
    },
    onTimeConfirm(value) {
      this.ruleForm.useTime = this.$d(value).format("YYYY-MM-DD HH:mm:ss");
      this.useTime = value;
      this.showTimePicker = false;
    },
    onCarConfirm(value) {
      this.ruleForm.cartCode = value.bizCode;
      this.ruleForm.cartName = value.cartName;
      this.showCarPicker = false;
    },
    onStaffConfirm(value) {
      this.ruleForm.useStaffCode = value.staffCode;
      this.ruleForm.useStaffName = value.userName;
      this.showStaffPicker = false;
    },
    onOversize(file) {
      Toast("文件大小不能超过 10MB");
    },
    // 返回布尔值
    beforeRead(file) {
      const isType = ["image/png", "image/jpeg"].includes(file.type);
      if (!isType) {
        Toast("上传小票只支持 JPG/PNG 格式!");
        return false;
      }
      return true;
    },
    afterReadFile(file) {
      this.fileList = [];
      this.loading = true;
      const form = new FormData();
      form.append("file", file.file);
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
      });
      uploadImg(form).then((res) => {
        Toast.clear();
        this.loading = false;
        if (res) {
          let { data, code, msg } = res;
          if (code == 200) {
            Toast({
              type: "success",
              message: msg,
            });
            this.fileList.push({
              name: file.file.name,
              code: data.fileCode,
              url: data.fileUrl,
            });
          }
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    validKeep() {
      this.$refs["washForm"]
        .validate([
          "cardName",
          "cardNo",
          "useTime",
          "useMoney",
          "cartName",
          "useStaffName",
        ])
        .then((valid) => {
          this.add();
        });
    },
    add() {
      if (this.fileList && this.fileList.length > 0) {
        this.ruleForm.useImgCode = this.fileList
          .map((e) => {
            return e.code;
          })
          .toString();
      }
      addConsumeBill(this.ruleForm).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            Toast.success(msg);
            this.goBack();
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.add-wash-consume {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .main-content {
    flex: 1;
    .title-box {
      background: #f0f6ff;
      .title {
        text-align: center;
        .red {
          color: red;
        }
      }
    }
    ::v-deep .van-cell {
      display: flex;
      flex-direction: column;
      padding: 0.3rem;
    }
    ::v-deep .van-cell__title {
      width: 100%;
    }
    .text {
      ::v-deep .van-field__control {
        height: 1.5rem;
      }
    }
  }
  .footer-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 2.18rem;
      height: 0.8rem;
      background: #e5f4fd;
      border-radius: 0.4rem 0 0 0.4rem;
      font-size: 0.26rem;
      color: #0094ee;
      line-height: 0.37rem;
    }

    .reject-btn {
      width: 2.18rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0 0.4rem 0.4rem 0;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
      font-size: 0.26rem;

      line-height: 0.37rem;
    }
  }
}
</style>

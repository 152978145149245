import request from "@/utils/request";

// 编辑加油卡
export function editCard(data) {
  return request({
    url: "/car/cardManger/editCard",
    method: "post",
    data,
  });
}
// 加油卡消费列表导出
export function exportCard(params) {
  return request({
    url: "/car/cardManger/export",
    method: "get",
    params,
    responseType: "blob",
  });
}

// 获取加油卡详情
export function queryCardInfo(params) {
  return request({
    url: "/car/cardManger/queryCardInfo",
    method: "get",
    params,
  });
}
// 获取加油卡列表
export function queryCardList(params) {
  return request({
    url: "/car/cardManger/queryCardList",
    method: "get",
    params,
  });
}

// 获取加油卡消费列表
export function queryCardUseList(params) {
  return request({
    url: "/car/cardManger/queryCardUseList",
    method: "get",
    params,
  });
}

// 删除加油卡
export function removeCard(data) {
  return request({
    url: `/car/cardManger/removeCard/${data.cardNo}`,
    method: "post",
  });
}

// 添加加油卡
export function saveCard(data) {
  return request({
    url: `/car/cardManger/saveCard`,
    method: "post",
    data,
  });
}
/*
 
获取洗车卡列表
*/
export function queryWashCardList(params) {
  return request({
    url: `/car/cardManger/queryWashCardList`,
    method: "GET",
    params,
  });
}
/*
 * 添加消费记录
 */
export function addConsumeBill(data) {
  return request({
    url: "/car/cardManger/addConsumeBill",
    method: "POST",
    data,
  });
}
/*
 
查询所有车辆列表
*/
export function selectAll(params) {
  return request({
    url: `/cart/manage/selectAll`,
    method: "GET",
    params,
  });
}
